<template>
  <CRow>
    <CCol col="12" xl="12" v-if="!attention_detail">
      <transition name="slide">
        <CRow>
          <CCol col="12" xl="12">
            <CCard>
              <CCardHeader>
                <div class="d-flex justify-content-between" style="font-size: 16px;">
                <p>Atenciones</p>
<!--                 <CButton
                  color="primary"
                  size="sm"
                  class="m-1"
                  @click="updateUser(false, $event)"
                >
                  Agregar
                </CButton> -->

                <p><span style="font-weight: 500;">Total:</span> {{totalAttentions}}</p>
                </div>

                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center justify-content-center;">
                    <p class="mb-0 mr-2">Vídeo Compuesto:</p> <CInputCheckbox :checked.sync="composed_video" style="height: 20px;"/> 
                  </div>

                  <div style="display: flex; align-items: center">
                    <CSpinner
                      color="primary"
                      v-if="loaderSearch"
                      size="sm"
                      class="mb-3 mr-2"
                    />
                    <font-awesome-icon icon="search" class="mb-3 mr-2" v-else />
                    <CInput v-model="search" placeholder="Buscar usuario" />
                  </div>
                </div>
              </CCardHeader>

              <CCardBody>
                <CDataTable
                  v-show="!loading"
                  hover
                  striped
                  :items="attentions"
                  :fields="fields"
                  :items-per-page="limit"
                >
<!--                   <template #last_attention="data">
                    <td v-if="data.item.last_attention.name">{{data.item.last_attention.name}}
                       <br>
                       {{data.item.last_attention.date}}
                    </td>
                    <td v-else>
                      {{data.item.last_attention}}
                    </td>
                  </template> -->
                  <template #videocall="data">
                    <div style="margin-left: 36px;">
                      <CButton
                        v-c-tooltip="'LLamar'"
                        color="success"
                        size="sm"
                        class="m-1"
                        @click="openModalVideoCall(data.item.callee_id, data.item.id)"
                      >
                        <font-awesome-icon icon="phone" />
                      </CButton>
                      <CButton
                        v-c-tooltip="'Ver Detalle'"
                        color="info"
                        size="sm"
                        class="m-1"
                        @click="getDetails(data.item.id)"
                      >
                        <font-awesome-icon icon="info-circle" />
                      </CButton>
                    </div>
                  </template>
                </CDataTable>
                <div style="width: 200px; margin: 0 auto;">
              <CPagination
                :active-page.sync="page"
                :pages="Math.ceil(totalAttentions / limit)"
                align="center"
                size="lg"
              />
            </div>
                <div 
                  v-if="loading"
                  class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
                  <CSpinner
                    style="width:4rem;height:4rem;"
                    color="danger"
                  />
                  <p class="text-secondary my-2">Cargando datos ...</p>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </transition>
    </CCol>
    

    <ModalVideoCall
      :user_is_in_attention="user_is_in_attention"
      :modalVideoCall="modalVideoCall"
      :close="close"
      :user_id="user_id"
      :attention_id="attention_id"
    />
  </CRow>
</template>

<script>
import { getAttentions, checkUserInAttention } from '../../api/atenciones'
import ModalVideoCall from '../atenciones/ModalVideoCall.vue'
import moment from 'moment'
import { Carousel, Slide } from 'vue-carousel';
import _ from "lodash/lodash";

export default {
components: { Carousel, Slide, ModalVideoCall },
    data () {
        return {
            //tabla
            page: 1,
            limit: 10,
            totalAttentions: 0,
            loading: true,
            attentions: [],
            fields: [
                {
                  key: "name",
                  label: "Nombre",
                  _props: { scope: "col" },
                },
                {
                  key: "operator",
                  label: "Operador",
                  _props: { scope: "col" },
                },
                {
                  key: "date",
                  label: "Inicio",
                  _props: { scope: "col" },
                },
                {
                  key: "duration",
                  label: "Duración (Mins)",
                  _props: { scope: "col" },
                },
                {
                  key: "videocall",
                  label: "Detalle de Atención",
                  _props: { scope: "col" },
                },
            ],
            //modal videocall props
            modalVideoCall: false,
            user_id: null,
            attention_id: null,
            attention_detail: null,
            user_is_in_attention: null,

            //filters
            search: "",
            loaderSearch: false,
            composed_video: false
        }
    },
    methods: {
      getAttentions() {
        const data = {
          page: this.page,
          limit: this.limit,
          search: this.search,
          composed_video: this.composed_video ? 1 : 0
        }

        getAttentions(data).then(res => {
            this.loading = false;
            this.totalAttentions = res.attentions_count
            this.attentions = res.attentions.map(attention => {
                this.loaderSearch = false;
                const duration = moment(moment(attention?.end).diff(moment(attention?.start))).format('mm:ss') == "Invalid date" ? 'Sin Registro' : moment(moment(attention?.end).diff(moment(attention?.start))).format('mm:ss')
                return {
                  operator: attention?.caller?.name,
                  name: attention?.callee?.name,
                  date: moment(attention?.start).format('DD-MM-YYYY HH:mm'),
                  duration,
                  id: attention?.id,
                  callee_id:attention?.callee_id
                }
            })
        }).catch(e => {
            console.log(e);
        })
        },
      getDetails(attention_id) {
        this.$router.push(`/atenciones-general/${attention_id}`)
    },
    openModalVideoCall(user_id, attention_id) {
      checkUserInAttention({ user_id })
        .then((res) => {
          this.modalVideoCall = true;
          this.user_id = user_id;
          this.attention_id = attention_id;
          this.user_is_in_attention = res.user_is_in_attention;

          if (res.user_is_in_attention) {
            this.getAttentions();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    close() {
      this.modalVideoCall = false;
    },
    },
    mounted () {
        this.getAttentions();
    },
      watch: {
        page: _.debounce(function (page) {
           this.getAttentions();
    }, 500),
    search: _.debounce(function (value) {
      if (this.search.length > 2 || this.search == "") {
        this.loaderSearch = true;
        this.getAttentions();
      }
    }, 1000),
    composed_video() {
      this.getAttentions();
      },
    }
}
</script>

<style lang="scss" scoped>

</style>